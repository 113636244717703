<template>
  <component
    :is="layoutComponent"
    :articles="articles"
    :layout="layout"
    :headline="sliderHeadline"
    :headline-level="headlineLevel"
  />
</template>

<script>
  import Columns from "./columns.vue"
  import Grid from "./grid.vue"
  import Slider from "./slider.vue"
  import List from "./list.vue"

  const LAYOUT_COMPONENTS = {
    columns: Columns,
    grid: Grid,
    list: List,
    slider: Slider,
  }

  export default {
    name: "Articles",
    props: {
      articles: {
        type: Array,
        default: () => [],
      },
      layout: {
        type: String,
        default: "grid",
        validator: (value) => {
          return Object.keys(LAYOUT_COMPONENTS).includes(value)
        },
      },
      sliderHeadline: {
        type: String,
        default: "Articles Slider",
      },
      headlineLevel: {
        type: [Number, String],
        default: 3,
      },
    },
    computed: {
      layoutComponent() {
        return LAYOUT_COMPONENTS[this.layout]
      },
    },
  }
</script>
