<template>
  <Container space-before space-after>
    <div class="standard-grid">
      <div
        v-for="(article, index) in articles"
        :key="article.id + 'item'"
        class="articles-grid-item"
      >
        <NuxtLink
          :to="article.link"
          class="articles-grid-link"
          :aria-labelledby="`articles-grid-title-standart-${localId}-${index}`"
          :title="article.imageAltText"
        >
          <picture class="articles-grid-picture">
            <source
              v-for="imgType in createFormatArray(article.srcset)"
              :key="imgType.type"
              :type="imgType.type"
              :srcset="imgType.srcsetstring"
              :sizes="`
                (min-width: 1344px) ${
                  article.position === 1 ? '829px' : '394px'
                },
                (min-width: 1050px) calc((100vw - 104px) * ${
                  article.position === 1 ? '.66' : '.33'
                }),
                (min-width: 987px) calc((100vw - 120px) / 3)
                (min-width: 600px) calc((100vw - 64px) / 2)
                calc(100vw - 32px)
              `"
            />
            <img
              :src="article.image"
              alt=""
              class="articles-grid-image"
              loading="lazy"
            />
          </picture>
        </NuxtLink>
        <NuxtLink :to="article.link" class="articles-grid-info">
          <div
            :id="`articles-grid-title-standart-${localId}-${index}`"
            class="articles-grid-info-title"
          >
            {{ article.title }}
          </div>
          <div class="articles-grid-info-copy">
            {{ article.copy }}
          </div>
        </NuxtLink>
      </div>
    </div>
  </Container>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    mixins: [createFormatArray],
    props: {
      articles: {
        type: Array,
        default: () => [],
      },

      sliderHeadline: {
        type: String,
        default: "Articles Slider",
      },

      headlineLevel: {
        type: [Number, String],
        default: 3,
      },
    },
    data() {
      return {
        localId: Math.floor(Math.random() * 1000, 5),
      }
    },
  }
</script>

<style lang="scss" scoped>
  .standard-grid {
    display: grid;
    grid-gap: $space-m;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-bottom: $space-m;

    @include viewport("sm") {
      grid-gap: $space-s * 2;
    }
    @include viewport("mini") {
      grid-gap: $space-s;
      display: flex;
      flex-direction: column;
    }
    @include viewport("md", "lg") {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-auto-rows: min-content;
      grid-column-gap: $space-m;
      grid-row-gap: $space-s;
    }
  }

  .articles-grid-item {
    display: flex;
    gap: $space-s;
    flex-direction: column;

    .articles-grid-link {
      flex-shrink: 0;
      aspect-ratio: 3 / 2;
    }

    @include viewport("md", "lg") {
      &:nth-of-type(1) {
        grid-column: 1 / 3;
        grid-row: 1 / 3;

        .articles-grid-link {
          height: 500px;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        .articles-grid-link {
          aspect-ratio: 4 / 2;
        }
      }
    }
  }

  .articles-grid-link {
    min-height: 0px;
    border-radius: $border-radius;
    overflow: hidden;
    transition:
      box-shadow 120ms ease-in-out,
      transform 120ms ease-in-out;
    box-shadow: $shadow;
    will-change: transform, box-shadow;
    backface-visibility: hidden;
    &:hover {
      transform: scale3d(1.05, 1.05, 1.05);
      box-shadow: $hover-shadow;
    }
    &:focus {
      transform: scale3d(1.05, 1.05, 1.05);
      box-shadow: $focus-shadow;
    }
  }

  .articles-grid-picture {
    width: 100%;
  }

  .articles-grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .articles-grid-info {
    color: inherit;
    text-decoration: none;
    padding-bottom: $space-s;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .articles-grid-info-title {
    @apply h4;
  }

  .articles-grid-info-copy {
    color: $gray-text;
  }
</style>
