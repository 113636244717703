<template>
  <Slider
    slider-type="articles"
    :items="sliderArticles"
    :element-size="300"
    :headline="sliderHeadline"
    :headline-level="headlineLevel"
  />
</template>

<script>
  import Slider from "~/components/Slider"

  export default {
    components: {
      Slider,
    },
    props: {
      articles: {
        type: Array,
        default: () => [],
      },
      sliderHeadline: {
        type: String,
        default: "Articles Slider",
      },
      headlineLevel: {
        type: Number,
        default: 3,
      },
    },
    computed: {
      sliderArticles() {
        const so = []

        Object.values(this.articles).forEach((article) =>
          so.push({
            name: article.title,
            link: article.link,
            src: article.image,
            id: article.id,
          }),
        )

        return so
      },
    },
  }
</script>
