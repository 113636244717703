<template>
  <Container space-before space-after>
    <div class="columns">
      <article v-for="article in articles" :key="article.id">
        <NuxtLink
          :to="article.link"
          class="link-picture"
          :aria-labelledby="`articles-column-title-${article.id}`"
          :title="article.imageAltText"
        >
          <picture>
            <source
              v-for="imgType in createFormatArray(article.srcset)"
              :key="imgType.type"
              :type="imgType.type"
              :srcset="imgType.srcsetstring"
              :sizes="`
                (min-width: 1344px) 394px,
                (min-width: 1050px) calc((100vw - 104px) * .33,
                (min-width: 987px) calc((100vw - 120px) / 3)
                (min-width: 600px) calc((100vw - 64px) / 2)
                calc(100vw - 32px)
              `"
            />
            <img :src="article.image" alt="" class="image" loading="lazy" />
          </picture>
        </NuxtLink>
        <NuxtLink :to="article.link" class="text">
          <h3 :id="`articles-column-title-${article.id}`" class="text-title">
            {{ article.title }}
          </h3>
          <div class="text-copy">
            {{ article.copy }}
          </div>
        </NuxtLink>
      </article>
    </div>
  </Container>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    mixins: [createFormatArray],
    props: {
      articles: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>

<style lang="scss" scoped>
  .columns {
    display: grid;
    grid-gap: $space-m;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-bottom: $space-m;

    @include viewport("sm") {
      grid-gap: $space-s * 2;
    }

    @include viewport("mini") {
      grid-gap: $space-s;
      display: flex;
      flex-direction: column;
    }

    @include viewport("md", "lg") {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-auto-columns: 1fr;
      grid-column-gap: $space-m;
      grid-row-gap: $space-s;

      article {
        grid-row: 1;

        &:nth-child(n + 5) {
          grid-row: auto;
        }
      }
    }
  }

  article {
    display: flex;
    gap: $space-s;
    flex-direction: column;

    .link-picture {
      flex-shrink: 0;
      aspect-ratio: 3 / 2;
    }
  }

  .link-picture {
    min-height: 0px;
    border-radius: $border-radius;
    overflow: hidden;
    transition:
      box-shadow 120ms ease-in-out,
      transform 120ms ease-in-out;
    box-shadow: $shadow;
    will-change: transform, box-shadow;
    backface-visibility: hidden;

    &[href]:hover {
      transform: scale3d(1.05, 1.05, 1.05);
      box-shadow: $hover-shadow;
    }

    &[href]:focus {
      transform: scale3d(1.05, 1.05, 1.05);
      box-shadow: $focus-shadow;
    }

    picture {
      width: 100%;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text {
    color: inherit;
    text-decoration: none;
    padding-bottom: $space-s;

    &[href]:hover,
    &[href]:focus {
      text-decoration: underline;
    }
  }

  .text-title {
    @apply h4;
    margin: $base-spacing 0;
  }

  .text-copy {
    color: $gray-text;
  }
</style>
